
import '../styles/css/confirm.css';
import $ from "jquery";
import {confirm_dialog,confirm_delete,confirm_form} from "../outils/OutilsConfirm";
$(document).ready(function () {

    confirm_dialog()
    confirm_delete()
    confirm_form()
})



